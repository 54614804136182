import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, filter, tap } from 'rxjs';
import { RootStore, SidebarState, User, UserRole } from 'src/app/store/root.store';

import { delayedFadeIn, fadeIn, growDownOnEnter } from 'src/app/animations/animations';
import { NavigationEnd, Router } from '@angular/router';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { UserService } from 'src/app/services/user.service';

const roleNames = [
  { role: UserRole.HealthHomeSocialWorker, name: 'Health Home - Social Worker' },
  { role: UserRole.HealthHomeDietician, name: 'Health Home - Dietician' },
  { role: UserRole.HealthHomeNurseNavigator, name: 'Health Home - Nurse Navigator' },
  { role: UserRole.HealthHomeAdminAssistant, name: 'Health Home - Administrative Assistant' },
  { role: UserRole.HealthHomeDirector, name: 'Health Home - Director' },
  { role: UserRole.PDNProvider, name: 'PDN Provider' },
  { role: UserRole.MedicalServicesProviderPhysician, name: 'Medical Services Provider - Physician' },
  { role: UserRole.MedicalServicesProviderStaff, name: 'Medical Services Provider - Staff' },
  { role: UserRole.NonMedicalServicesProvider, name: 'Non-Medical Services Provider' },
  { role: UserRole.ManagedCare, name: 'Managed Care' },
  { role: UserRole.Patient, name: 'Patient' },
]

@Component({
  selector: 'hh-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [ delayedFadeIn, growDownOnEnter, fadeIn ]
})
export class SideNavComponent implements OnInit {
  public sidebarState$: Observable<SidebarState>;
  public healthHomeAdminLinksState$ = new BehaviorSubject<boolean>(false);
  public healthHomeAdminLinksStateCollapsed$ = new BehaviorSubject<boolean>(false);
  public healthHomeAdminActive$ = new BehaviorSubject<boolean>(false);
  public currentUser$ = new BehaviorSubject<User | null>(null)

  //Feature flags
  userListFF: boolean = false;
  rolesListFF: boolean = false;
  exportGoalEventsFF: boolean = false;
  providerListFF: boolean = false;

  constructor(
    private store: RootStore,
    private router: Router,
    private featureFlagService: FeatureFlagService,
    private userService: UserService
  ) {
    this.sidebarState$ = this.store.sidebarState$;
    this.currentUser$ = this.store.currentUser$;
    this.router.events.pipe(
      tap(() => this.healthHomeAdminLinksStateCollapsed$.next(false)),
      filter((e) => e instanceof NavigationEnd),
    ).subscribe((e: any) => {
      if (e.url.includes('hh-admin') && this.store.sidebarState$.getValue() === 'expanded') {
        this.healthHomeAdminLinksState$.next(true);
        this.healthHomeAdminActive$.next(true);
      } else {
        this.healthHomeAdminLinksState$.next(false);
        this.healthHomeAdminActive$.next(false);
      }
    })
  }

  ngOnInit(): void {
    this.userListFF = this.featureFlagService.isFF('user-list');
    this.rolesListFF = this.featureFlagService.isFF('role-list');
    this.exportGoalEventsFF = this.featureFlagService.isFF('export-goal-events');
    this.providerListFF = this.featureFlagService.isFF('provider-list');
  }

  toggleHealthHomeAdminLinks() {
    const value = this.healthHomeAdminLinksState$.getValue();
    const collapsedState = this.healthHomeAdminLinksStateCollapsed$.getValue();
    this.healthHomeAdminLinksState$.next(!value);
    this.healthHomeAdminLinksStateCollapsed$.next(!collapsedState);
  }

  logout(): void {
    this.store.currentUser$.next(null);
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('hh-session');
    this.router.navigateByUrl('/login');
  }

  getUserRole(role: UserRole): string {
    return this.currentUser$.getValue()?.roleNames!;
    //return roleNames.find((item) => item.role === role)!.name;
  }

  isAdmin(): boolean {
    return this.currentUser$.getValue()?.isAdmin!;
  }

  hasPermission(routeName: string){
    return this.userService.hasPermissions(routeName);
  }
}

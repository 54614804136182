import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { slideLeftOnEnter } from 'src/app/animations/animations';

export type SnackbarType = 'success' | 'failure' | 'warning' | 'notificaton';

@Component({
  selector: 'hh-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  animations: [slideLeftOnEnter]
})
export class SnackbarComponent implements OnInit, OnChanges {
  @Input() text: string = '';
  @Input() icon: string = 'close';
  @Input() type: SnackbarType = 'notificaton';
  @Input() duration: number = 5000;
  showSnackbar = false;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showSnackbar = true;

    setTimeout(() => {
      this.showSnackbar = false;
    }, this.duration)
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { fadeInOut } from 'src/app/animations/animations';
import { RootStore, SidebarState } from 'src/app/store/root.store';

@Component({
  selector: 'hh-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [fadeInOut]
})
export class BannerComponent implements OnInit, OnChanges {
  @Input() message: string = '';
  @Input() showBanner: boolean = false;
  @Input() fullPage: boolean = false;
  @Input() bannerState: string = '';
  sidebarCollapsed$: Observable<SidebarState>;
  bannerColor = '';

  constructor(private store: RootStore) {
    this.sidebarCollapsed$ = this.store.sidebarState$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['bannerState']) {
        switch (changes['bannerState'].currentValue) {
          case 'success':
            this.bannerColor = '#72b879';
            break;
          case 'error':
            this.bannerColor = '#ed7272';
            break;
          default:
            this.bannerColor = '#f4f091';
            break;
        }
      }

      if (changes['showBanner'] && changes['showBanner'].currentValue) {
        setTimeout(() => {
          this.store.showBanner$.next(false);
        }, 10000);
      }
    }
  }

  ngOnInit(): void {}

  closeBanner() {
    this.showBanner = false;
    this.store.showBanner$.next(false);
  }
}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RootStore, SidebarState } from 'src/app/store/root.store';
import { Router } from '@angular/router';
import { NotificationsService } from '../../services/notifications.service';
import { NotificationCountService } from '../../services/notificationCount.service';
import Push from 'push.js';



@Component({
  selector: 'hh-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  public sidebarState$: Observable<SidebarState>;

  notificationsList: any = [];
  notificationsCount: number = 0;
  constructor(
    private store: RootStore,
    private notificationsService: NotificationsService,
    public notificationCountService: NotificationCountService,
    private router: Router
  ) {
    this.sidebarState$ = this.store.sidebarState$;
    this.notificationsService.getNotifications().subscribe(notification =>{
      const nList = [...notification];
      this.notificationsList = nList.filter(n => n.cleared !== true);
      this.notificationsCount = this.notificationsList?.length;
      this.notificationCountService.setNotificationCount(this.notificationsCount);
    });
  }

  ngOnInit(): void {
    this.notificationsService.getNotificationEvent().subscribe(
      (event: any) => {
        const userId = this.store.currentUser$.getValue()?.id;
        const notificationData = JSON.parse(event.data);
    
        if (Array.isArray(notificationData)) {
          const userNotifications = notificationData.filter((notification: any) => {
            return notification.userId === userId;
          });
          userNotifications.forEach((notification: any) => {
            this.notificationCountService.incrementNotificationCount();
            Push.create(notification.title, {
              body: notification.notes,
            });
          });
        }
      },
      (error:any) => {
        console.error('Error in SSE connection:', error);
      },
      () => {
        console.log('SSE connection closed');
      }
    );
  }

  navigateToNotificationList() {
    this.router.navigate(['/notification']);
  }
  
}

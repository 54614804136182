import { style, animate, trigger, transition, query, animateChild, group } from '@angular/animations';

export const slideLeftOnEnter = trigger('slideLeftOnEnter', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateX(20px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateX(0)' }))
    ])
])

export const slideRightOnEnter = trigger('slideRightOnEnter', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-20px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateX(0)' }))
    ])
])

export const slideUpOnEnter = trigger('slideUpOnEnter', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateY(0)' }))
    ])
])

export const slideDownOnEnter = trigger('slideDownOnEnter', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateY(0)' }))
    ])
])

export const slideLeftOnEnterLeave = trigger('slideLeftOnEnterLeave', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateX(20px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateX(0)' }))
    ]),
    transition(':leave', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate('200ms', style({ opacity: 0, transform: 'translateX(20px)' }))
    ])
])

export const slideRightOnEnterLeave = trigger('slideRightOnEnterLeave', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-20px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateX(0)' }))
    ]),
    transition(':leave', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate('200ms', style({ opacity: 0, transform: 'translateX(-20px)' }))
    ])
])

export const slideUpOnEnterLeave = trigger('slideUpOnEnterLeave', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateY(0)' }))
    ]),
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms', style({ opacity: 0, transform: 'translateY(20px)' }))
    ])
])

export const slideDownOnEnterLeave = trigger('slideDownOnEnterLeave', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateY(0)' }))
    ]),
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms', style({ opacity: 0, transform: 'translateY(-20px)' }))
    ])
])

export const growDownOnEnter = trigger('growDownOnEnter', [
    transition(':enter', [
        style({ height: '0' }),
        animate('100ms', style({ height: '*' }))
    ]),
    transition(':leave', [
        style({ height: '*' }),
        animate('100ms', style({ height: '0' }))
    ])
])

export const drawerSlideIn = trigger('drawerSlideIn', [
    transition(':enter', [
        style({ right: '-100%' }),
        animate('400ms', style({ right: '0' }))
    ]),
      transition(':leave', [
        style({ right: '0' }),
        animate('400ms', style({ right: '-100%' }))
    ])
]);

export const fadeInOut = trigger('fadeInOut', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 }))
    ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms', style({ opacity: 0 }))
    ])
]);

export const delayedFadeIn = trigger('delayedFadeIn', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms 300ms', style({ opacity: 1 }))
    ])
]);

export const fadeOut = trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms', style({ opacity: 0 }))
    ])
]);

export const fadeIn = trigger('fadeIn', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('200ms', style({ opacity: 1 }))
  ])
]);

export const flowSlideIn = trigger('flowSlideIn', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateX(20px)', height: '0px' }),
        animate('300ms', style({ opacity: 1, transform: 'translateX(0)', height: '70px' }))
    ])
])

export const routeAnimation = trigger('routeAnimation', [
    transition('* <=> *', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%'
            })
        ], { optional: true },),
        query(':enter', [style({ left: '100px', opacity: 0 })], { optional: true },),
        query(':leave', animateChild(), { optional: true },),
        group([
            query(':leave', [animate('0s ease-out', style({ opacity: 0 }))], { optional: true },),
            query(':enter', [animate('300ms 500ms ease-out', style({ left: 0, opacity: 1 }))], { optional: true },)
        ]),
        query(':enter', animateChild(), { optional: true },)
        // style({ position: 'relative' }),
        // query(':enter', [
        //     style({
        //         position: 'absolute',
        //         top: 110,
        //         right: 0
        //     })
        // ]),
        // query(':enter', [ style({ right: '-100%', opacity: 0 }) ]),
        // query(':leave', [ style({ opacity: 1 }) ]),
        // query(':leave', [ animate('5s', style({ opacity: 0 })) ]),
        // query(':enter', [ animate('5s', style({ right: '0%', opacity: 1 })) ]),
        // query(':enter', animateChild())
    ])
])
<div
  *ngIf="showBanner"
  [@fadeInOut]
  [ngClass]="{ sidebar_collapsed: (sidebarCollapsed$ | async) === 'collapsed', full_page: fullPage }"
  [ngStyle]="{ 'background-color': bannerColor }"
  class="banner"
>
  <div>{{ message }}</div>
  <span (click)="closeBanner()" class="material-icons-round close">close</span>
</div>

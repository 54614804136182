import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RootStore } from '../store/root.store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private store: RootStore, private authService: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const tokenExists = !!sessionStorage.getItem('token');
    let user = this.store.currentUser$.getValue();

    if (!tokenExists) {
      this.router.navigateByUrl('/login');
      return false;
    }

    return of(user).pipe(
      switchMap((user) => {
        if (!user) {
          return this.authService.getUser();
        }
        return of(user);
      }),
      map((user) => {
        return true;
      })
    );
  }
}

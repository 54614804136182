<mat-progress-bar *ngIf="!(triggerLoaderComplete$ | async)" [@fadeInOut] mode="indeterminate" class="page_loader"></mat-progress-bar>
<hh-banner
  class="external"
  [showBanner]="(showBanner$ | async)!"
  [message]="(bannerMessage$ | async)!"
  [bannerState]="(bannerState$ | async)!"
  [fullPage]="true"
></hh-banner>
<hh-snackbar
  *ngIf="snackbarState && showSnackbar"
  class="external"
  [text]="snackbarState.text!"
  [icon]="snackbarState.icon!"
  [type]="snackbarState.type!"
  [duration]="snackbarState.duration!"
></hh-snackbar>
<ng-container *ngIf="sidebarState$ | async as state">
  <ng-container *ngIf="!(hideToolbars$ | async)">
    <hh-side-nav></hh-side-nav>
    <hh-top-nav></hh-top-nav>
    <div
      (click)="toggleSidebar()"
      class="expand_collapse"
      [ngClass]="{ expanded: state === 'expanded', collapsed: state === 'collapsed' }"
      [matTooltip]="state === 'expanded' ? 'Collapse' : 'Expand'"
      matTooltipPosition="below"
    >
      <span class="material-icons-round icon">chevron_right</span>
    </div>
  </ng-container>
  <div [@routeAnimation]="prepareRoute(outlet)" [ngClass]="{ dashboard: !(hideToolbars$ | async), sidebar_collapsed: state === 'collapsed' }">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</ng-container>
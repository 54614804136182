import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationCountService {
  private _notificationCount = new BehaviorSubject<number>(0);
  notificationCount$ = this._notificationCount.asObservable();

  constructor() {}

  setNotificationCount(count: number) {
    this._notificationCount.next(count);
  }

  incrementNotificationCount() {
    this._notificationCount.next(this._notificationCount.value + 1);
  }

  decrementNotificationCount() {
    const currentCount = this._notificationCount.value;
    if (currentCount > 0) {
      this._notificationCount.next(currentCount - 1);
    }
  }
}

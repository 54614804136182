import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

export class AuthInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = sessionStorage.getItem('token');
        const clonedRequest = req.clone({ headers: req.headers.append('Authorization', `Bearer ${token}`) });

        if (token) {
            return next.handle(clonedRequest);
        } 

        return next.handle(req);
    }
}
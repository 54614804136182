import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RootStore, User } from '../store/root.store';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(
    private http: HttpClient,private _zone: NgZone,
    private store: RootStore,
  ) { }
  
  /**
   * Adding a notification service
   * @param body 
   * @returns 
   */
  addNotification(body: {userId:number,entity:string, entityId:number, title:string, notes:string}){
    return this.http.post(`${environment.apiUrl}/notifications/add`,body);
  }

  /**
   * Get all notifications of logged in user
   * @returns 
   */
  getNotifications(): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/notifications/get-all`);
  }

  /**
   * Get all sent notifications of logged in user
   * @returns 
   */
  getSentNotifications(): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/notifications/get-sent-notification`);
  }

  /**
   * Mark notification as read
   * @returns 
   */
  markAsRead(id:number): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/notifications/mark-as-read/${id}`);
  }

  /**
   * Mark notification as read
   * @returns 
   */
  snoozeNotification(id:number,duration:number): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/notifications/snooze-until/${id}/${duration}`);
  }

  /**
  * Creates event source
  * @param url
  */
  getNotificationEvent(): Observable<MessageEvent> {
    const url = `${environment.apiUrl}/notifications/notification-event`;
    return new Observable((observer) => {
      
      const eventSource = new EventSource(url);
      eventSource.onmessage = (event) => {
        console.log(event,'Notification event');
        this._zone.run(() => {
          observer.next(event);
        });
      };
      eventSource.onerror = (error) => {
        console.log(error,'Notification error');
        this._zone.run(() => {
          observer.error(error);
        });
      };
      return () => {
        eventSource.close();
      };

    });

  }
}
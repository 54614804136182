import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { RootStore } from '../store/root.store';
import { FeatureFlagService } from '../services/feature-flag.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {

  constructor(
    private featureFlagService: FeatureFlagService,
    private userService: UserService,
    private store: RootStore
  ) {}

  initializeApp(): Promise<void> {
    return new Promise((resolve, reject) => {
      forkJoin({
        flags: this.featureFlagService.getAllFeatureFlags(),
        permissions: this.userService.getUserPermissionsWithDetails()
      }).subscribe(
        ({ flags, permissions }) => {
          this.store.featureFlags$.next(flags);
          this.store.userPermissions$.next(permissions);
          resolve();
        },
        (error) => {
          console.error('Error fetching data:', error);
          resolve();
        }
      );
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RootStore } from '../store/root.store';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private store: RootStore
  ) { }

  getUserList(filters: {}): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/list`, filters);
  }
  getUserActiveRoles(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/get-roles`);
  }
  getUserProfile(id: string | number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/profile/${id}`).pipe(
      map(res => res),
      catchError((err: any) => {
        console.log('err: ', err);
        return of(err);
      })
    )
  }
  updateUserProfile(id: string | number, user:any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/profile/${id}`,user);
  }
  addUser(user:any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/users/add-user`,user);
  }
  getAllPermissions(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/get-permissions`);
  }
  savePermissionsOfUser(id:number|string, permissions:number[]){
    return this.http.post<any>(`${environment.apiUrl}/users/save-permissions`,{id:id,permissions:permissions});
  }
  getUserPermissions(id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/get-user-permissions/${id}`);
  }
  getUserPermissionsWithDetails(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/get-user-permissions-with-details`);
  }

  hasPermissions(route: string): boolean {
    const userPermissions = this.store.userPermissions$.getValue();
    
    if (!userPermissions || userPermissions.length === 0) {
      return false;
    }
    return userPermissions.some((permission: any) => {
      const routes = permission.routes?.split(',');
      return routes.includes(route);
    });
  }
}

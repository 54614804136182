import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { BehaviorSubject, debounceTime, delay, filter, map, Observable, tap } from 'rxjs';
import { RootStore, SidebarState, SnackbarState } from './store/root.store';
import { fadeInOut, routeAnimation } from './animations/animations';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'hh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimation, fadeInOut]
})
export class AppComponent implements OnInit {
  sidebarState$: Observable<SidebarState>;
  hideToolbars$ = new BehaviorSubject<boolean>(true);
  triggerLoader$ = new BehaviorSubject<boolean>(false);
  triggerLoaderComplete$: Observable<boolean>;
  showBanner$: Observable<boolean>;
  bannerMessage$: Observable<string>;
  bannerState$: Observable<string>;
  snackbarState: SnackbarState | null = null;
  showSnackbar = false;

  constructor(
    private store: RootStore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
    private userService: UserService,
  ) {
    this.sidebarState$ = this.store.sidebarState$;
    this.triggerLoaderComplete$ = this.store.pageLoaderComplete$.pipe(delay(0));
    this.showBanner$ = this.store.showBanner$;
    this.bannerMessage$ = this.store.bannerMessage$;
    this.bannerState$ = this.store.bannerState$;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        tap((e) => {
          if (e instanceof NavigationStart) {
            window.scroll({ top: 0, left: 0 });
            this.store.pageLoaderComplete$.next(false);
            this.triggerLoader$.next(true);
            setTimeout(() => {
              this.triggerLoader$.next(false);
            }, 600);
          }
        }),
        filter((e) => e instanceof NavigationEnd),
        map(() => {
          this.store.pageLoaderComplete$.next(true);
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data) {
              return child.snapshot.data;
            } else {
              return null;
            }
          }

          return null;
        })
      )
      .subscribe((data: any) => {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root);
        this.store.pageTitle$.next(title);

        this.hideToolbars$.next(!!data['hideToolbars']);
      });

      this.store.snackbarState$.pipe(
        tap((state) => {
          this.showSnackbar = true;
          this.snackbarState = JSON.parse(JSON.stringify(state));
        }),
        debounceTime(5000)
      ).subscribe(state => {
        this.showSnackbar = false;
      })
      this.initializeAppState();
  }

  toggleSidebar() {
    this.store.toggleSidebarState();
  }

  public prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animationState'];
  }

  private getTitle(state: any, parent: any): string {
    if (!parent) return '';

    if (parent && parent.snapshot.data && parent.snapshot.data['pageTitle']) {
      return parent.snapshot.data['pageTitle'];
    } else {
      return this.getTitle(state, state.firstChild(parent));
    }
  }

  setFeatureFlagsToStore(){
    this.featureFlagService.getAllFeatureFlags().subscribe(
        (flags) => {
            this.store.featureFlags$.next(flags);
        },
        (error) => {
            console.error('Error setting feature flags:', error);
        }
    );
  }

  private initializeAppState() {
    this.userService.getUserPermissionsWithDetails().subscribe(
      permissions => this.store.userPermissions$.next(permissions),
      error => console.error('Failed to fetch user permissions', error)
    );

    this.featureFlagService.getAllFeatureFlags().subscribe(
      flags => this.store.featureFlags$.next(flags),
      error => console.error('Failed to fetch feature flags', error)
    );
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RootStore } from '../store/root.store';

export interface FeatureFlag{
    id: number;
    flag: string;
    flag_description: string;
    users: string;
    ips: string;
    status: number;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
    constructor(
        private http: HttpClient,
        private store: RootStore
    ) { }

  getAllFeatureFlags(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/feature-flag`);
  }

  createFeatureFlag(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/feature-flag`, data);
  }

  updateFeatureFlag(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/feature-flag/update`, data);
  }

  deleteFeatureFlag(id: number) {
    return this.http.get(`${environment.apiUrl}/feature-flag/delete/${id}`);
  }

  isFF(flag: string){
    const allFeatureFlags = this.store.featureFlags$?.getValue();
    const user = this.store.currentUser$?.getValue();
    const filteredFlags = allFeatureFlags?.filter((featureFlag) => featureFlag.flag === flag)[0];
    if(filteredFlags && user){
      if (filteredFlags && filteredFlags.status === 1) {
        const domain = user.email.split('@')[1];
        const users = filteredFlags.users.split(',');
        if (users.includes('*@' + domain) || users.includes(user.email)) {
          return true;
        }
        if (filteredFlags.ips && filteredFlags.ips != '') {
          const ips = filteredFlags.ips.split(',');
          if (ips.includes(user.ip)) {
            return true;
          }
        }
        return false;
      }else if (filteredFlags && filteredFlags.status === 0) {
        return false;
      }else if (filteredFlags && filteredFlags.status === 2) {
        return true;
      } else {
        return false;
      }
    }else{
      return false;
    }
  }

}

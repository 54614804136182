import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SnackbarType } from '../libs/snackbar/snackbar.component';
import { FeatureFlag } from '../services/feature-flag.service';


export type SidebarState = 'expanded' | 'collapsed';
export type BannerState = 'error' | 'success' | 'default';

export interface SnackbarState {
    text?: string;
    icon?: string;
    type?: SnackbarType;
    duration?: number;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  password: string;
  picture: string;
  authType: string;
  lastSeen: Date;
  isActive: boolean;
  role: UserRole;
  ip: string;
  userHasRoles: any[];
  roleNames: string;
  isAdmin: boolean;
}

export enum UserRole {
  HealthHomeSocialWorker = 'HH-SW',
  HealthHomeDietician = 'HH-Die',
  HealthHomeNurseNavigator = 'HH-NN',
  HealthHomeAdminAssistant = 'HH-AA',
  HealthHomeDirector = 'HH-Dir',
  PDNProvider = 'PDN',
  MedicalServicesProviderPhysician = 'MSP-PH',
  MedicalServicesProviderStaff = 'MSP-S',
  NonMedicalServicesProvider = 'NON-MSP',
  ManagedCare = 'MC',
  Patient = 'PATIENT'
}

@Injectable({
  providedIn: 'root'
})
export class RootStore {
  public sidebarState$ = new BehaviorSubject<SidebarState>('expanded');
  public showBanner$ = new BehaviorSubject<boolean>(false);
  public bannerMessage$ = new BehaviorSubject<string>('');
  public bannerState$ = new BehaviorSubject<BannerState>('default');
  public currentUser$ = new BehaviorSubject<User | null>(null);
  public pageLoaderComplete$ = new BehaviorSubject<boolean>(false);
  public pageTitle$ = new BehaviorSubject<string>('');
  public snackbarState$ = new BehaviorSubject<SnackbarState | null>(null);
  public featureFlags$ = new BehaviorSubject<FeatureFlag[] | null>(null);
  public userPermissions$ = new BehaviorSubject<any[] | null>(null);

  constructor() {
    const sidebarState = localStorage.getItem('hh-sidebar-state') as SidebarState;
    if (sidebarState) {
      this.sidebarState$.next(sidebarState);
    }
  }

  toggleSidebarState() {
    const state = this.sidebarState$.getValue();
    const newState = state === 'expanded' ? 'collapsed' : 'expanded';
    this.sidebarState$.next(newState);
    localStorage.setItem('hh-sidebar-state', newState);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './top-nav.component';
import { MatBadgeModule } from '@angular/material/badge';


@NgModule({
  declarations: [
    TopNavComponent
  ],
  imports: [
    CommonModule,
    MatBadgeModule
  ],
  exports: [
    TopNavComponent
  ]
})
export class TopNavModule { }

<section class="topnav" [ngClass]="{ sidebar_collapsed: (sidebarState$ | async) === 'collapsed' }">
    <div class="topnav--header">

    </div>
    <div class="topnav--actions">
      <div class="notifications_action" (click)="navigateToNotificationList()">
        <span [matBadge]="(notificationCountService.notificationCount$ | async)" [matBadgeHidden]="(notificationCountService.notificationCount$ | async) === 0" matBadgeOverlap="false" matTooltip="Notifications" matTooltipPosition="below" class="material-icons-round">notifications</span>
      </div>
      <div class="help_actions">
        <span matTooltip="Help" matTooltipPosition="below" class="material-icons-round">help</span>
      </div>
    </div>
  </section>
  